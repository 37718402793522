.simple-card-page-paper-wrapper.page-setup {
  .simple-card-page-paper {
    .habits-list {
      width: 470px;
      justify-content: center;
      padding-left: 0;
      .habits-item {
        width: auto;
        padding: 15px 28px;
        margin: 10px;
        &:nth-child(5n),
        &:nth-child(10n) {
          margin-right: 30px;
        }
        &:nth-child(5n-1),
        &:nth-child(10n-1) {
          margin-left: 30px;
        }
      }
    }
  }
}
