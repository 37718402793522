@import "./mobile.scss";

#root {
  height: 100vh;
  overflow-y: hidden;
  .main-layout {
    min-width: 1200px;
  }
}

.main-container {
  padding: 72px 0px 0px 0px;
  max-width: 100%;
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 0;
  .main-content {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-top: 60px;
    padding-bottom: 15px;

    .left-nav {
      width: 300px;
      height: 100%;
    }

    .right-page-container {
      width: 840px;
    }

    .right-page {
      display: flex;
      flex-flow: row wrap;
    }

    .content-box {
      background: white;
      border-radius: 16px;
      padding: 30px 40px;
      box-shadow: none;

      .box-title {
        font-weight: 900;
        font-size: 26px;
        line-height: 32px;
        color: #323d47;
      }

      .box-sub-title {
        font-weight: 900;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba(36, 54, 70, 0.5);

        .box-sub-title-description {
          display: inline-block;
          margin-left: 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #878795;
          text-transform: none;
          letter-spacing: 0;
        }
      }

      .content-box-link {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.5px;
        text-transform: capitalize;
        color: #357392;
        cursor: pointer;
        display: inline-block;

        &.secondary {
          color: #959faf;
        }
      }
    }
  }
}

.footer {
  bottom: 0;
  left: auto;
  right: 0;
  position: static;
  height: 72px;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 29px;
  margin-top: 100px;

  border-top: 1px solid #e3e4e6;
  background-color: #f7f8fa;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: rgba(149, 159, 175, 0.5);

  .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #959faf;
    padding: 0px 16px;
  }
}

html body #root .header-topbar .header-topbar-nav-button {
  display: none;
}
