.page-edit-profile {
  .content-box.edit-profile-box {
    padding: 40px 40px 20px 40px;
    height: auto;
  }

  .avatar-area {
    margin-top: 32px;
    display: flex;
    flex-flow: row nowrap;
    .user-avatar.MuiAvatar-root,
    .user-avatar-fallback {
      height: 80px;
      width: 80px;
    }
    img {
      background-color: white;
    }

    .upload-file-form {
      margin-top: 30px;
      margin-left: 32px;
      .upload-file-info {
        display: flex;
        margin-top: 11px;
        .file-name {
          margin-left: 22px;
          font-size: 16px;
          line-height: 1.5;
          color: #323555;
        }
      }
      .supported-type {
        font-size: 11px;
        line-height: 2.18;
        color: #959faf;
      }
      .btn-upload {
        margin-top: 10px;
        height: 28px;
        width: 94px;
      }
      .btn-edit-profile {
        margin-right: 17px;
      }
    }
  }

  .profile-form {
    margin-top: 23px;
    .MuiGrid-root.MuiGrid-item {
      margin-top: 8px;
    }
  }
  label.MuiFormControlLabel-labelPlacementStart {
    padding-left: 0;
    margin-left: 0;
  }
  span.MuiFormControlLabel-label{
    letter-spacing: 0.75px;
    font-weight: 700;
    transform: translate(0px, 0px) scale(0.75)!important;
    color: #8B90A0;
    text-transform: uppercase;
  }
  button.btn-link {
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
    text-transform: none;
  }
  .actions-row {
    margin-top: 43px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn-submit-profile-form {
      height: 50px;
      width: 231px;
      justify-self: flex-end;
    }

    .btn-delete-account {
      margin-left: -4px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.57;
      color: #9b9b9b;
      background-color: white;
      text-transform: none;
      text-decoration-line: underline;
    }
  }

  .input-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 44px;
    margin-top: 24px;

    .input-button {
      padding: 0;
      height: 44px;
      width: 162px;
      font-weight: 500;
      font-size: 13px;
      line-height: 1.38;
      border-radius: 8px;
      background: #f7f8fa;
      color: #323d47;
      svg {
        height: 17px;
        width: 17px;
      }
      path {
        stroke: #323d47;
      }

      &.selected {
        background: #77b6ff;
        color: white;
        path {
          stroke: white;
        }
      }

      .MuiButton-label {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
      }
    }
  }
}

.common-confirm-dialog {
  .before-you-go {
    margin-top: 22px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    color: #323555;
    opacity: 0.5;
  }
  .before-you-go-tips {
    margin-top: 4px;
    padding-left: 15px;

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: #323d47;
    list-style-type: disc;
  }
  .last-thing {
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;
    color: #323d47;
  }
}
