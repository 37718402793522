.common-confirm-dialog {
  padding: 25px 32px;
  max-width: 564px;
  .dialog-sub-title {
    font-weight: 800;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(36, 54, 70, 0.5);
  }
  .dialog-title {
    margin-top: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.33;
    color: #323d47;
  }
  .dialog-actions {
    margin-top: 23px;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .dialog-btn-cancel {
      height: 40px;
      min-width: 100px;
      border: 1px solid #bfc6d4;
      border-radius: 5px;
      padding: 8px 31px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.6;
      text-align: center;
      background-color: white;
      color: #323d47;
      white-space: nowrap;
    }
    .dialog-btn-confirm {
      margin-left: 22px;
      height: 40px;
      min-width: 100px;
      border: none;
      border-radius: 5px;
      padding: 8px 31px;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.6;
      text-align: center;
      background-color: #ff5685;
      color: white;
      white-space: nowrap;
    }
  }
}

.confirm-delete-account .common-confirm-dialog {
  padding: 25px 32px;
  max-width: 564px;
  .dialog-sub-title {
    font-weight: 900;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(36, 54, 70, 0.5);
  }
  .dialog-title {
    margin-top: 8px;
    font-style: normal;
    font-weight: 900;
    font-size: 40px;
    line-height: 1.33;
    color: #102843;
  }
  .dialog-actions {
    margin-top: 23px;
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    .dialog-btn-cancel {
      order: 2;
      margin-left: 22px;
      height: 50px;
      border-radius: 5px;
      padding: 14px 24px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.6;
      text-align: center;
      background-color: white;
      color: #323d47;
      white-space: nowrap;
    }
    .dialog-btn-confirm {
      order: 1;
      height: 50px;
      border: none;
      border-radius: 5px;
      padding: 14px 24px;
      font-style: normal;
      line-height: 1.6;
      text-align: center;
      background-color: #357392;
      color: white;
      white-space: nowrap;
      .MuiButton-label {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
}

@keyframes Zoom {
  from {
    transform: scale(0.7);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.dialog-wrapper {
  overflow: auto;
  .MuiDialog-container.MuiDialog-scrollPaper {
    height: auto;
    min-height: 100%;
    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiPaper-rounded {
      overflow: hidden;
      animation: Zoom 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      max-height: none;
    }
  }
}
