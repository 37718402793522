.page-choose-plan {
  .MuiPaper-root.MuiCard-root.MuiPaper-rounded {
    padding-bottom: 0px;
  }

  &.choose-plan
    .simple-card-page-paper.MuiPaper-root.MuiCard-root.MuiPaper-rounded {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .select-plan {
    .plan-pro {
      width: 250px;
      height: 381px;
      background: white;
      border: 2px solid #D66D8E;
      box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      .plan-badge {
        color: white;
        background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
        margin-top: 24px;
      }
      .plan-price {
        margin-top: 18px;
      }
      .plan-features {
        margin-top: 24px;
      }
      .plan-select-button {
        margin-top: 24px;
        background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      }
    }
    .plan-free {
      width: 234px;
      height: 354px;
      background: #f7f8fa;
      border-radius: 8px;
      .plan-badge {
        color: #323d47;
        background: white;
        margin-top: 23px;
      }
      .plan-price {
        margin-top: 15px;
      }
      .plan-features {
        margin-top: 20px;
      }
      .plan-select-button {
        margin-top: 20px;
        background: transparent;
        color: #357392;
        border: 1px solid #357392;
      }
    }
    .plan-badge {
      padding: 0px 10px;
      height: 24px;
      border-radius: 4px;
      text-align: center;
      font-size: 16px;
      font-weight: 900;
      line-height: 1.7;
    }
    .plan-price {
      font-size: 32px;
      font-weight: 900;
      line-height: 1.25;
      color: #102843;
    }
    .plan-origin-price {
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      text-decoration-line: line-through;
      color: #c4c4c4;
    }
    .plan-divider {
      margin-top: 15px;
      border: 0.5px solid #bfc6d5;
      width: 72px;
    }
    .plan-features {
      width: 177px;
      height: 146px;
      .plan-features-items {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: #959faf;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        .icon-check {
          height: 12px;
          width: 12px;
        }
        .icon-close {
          height: 12px;
          width: 12px;
        }
      }
    }
    .plan-select-button {
      width: 126px;
      height: 34px;
      padding: 0;
    }
  }

  .see-addtional-plan {
    display: block;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
    color: #959faf;
    margin-top: 20px;
    margin-bottom: 15px;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: white;
  }

  &.addtional-plans {
    .MuiPaper-root.MuiCard-root {
      width: 740px;
      height: auto;
      padding-bottom: 30px;
    }
    .addtional-plans-wrapper {
      margin-top: 26px;
      width: 648px;
      .addtional-plans-grid {
        padding-bottom: 13px;
        display: flex;
        flex-flow: row wrap;
        margin: -15px -12px;
        .change-plan {
          margin: 15px 12px;
          cursor: pointer;
          &.selected .plan-card-component {
            border: 2px solid #61a6f5;
            border-radius: 8px;
          }
          .plan-card-component {
            border: 2px solid transparent;
            width: 200px;
            &.better-balance .card-bg-position {
              transform: scale(0.65);
              top: -55px;
              left: -93px;
            }
            &.keeping-keto .card-bg-position {
              left: -42px;
              top: -30px;
              transform: scale(0.75);
            }
            &.sugar-smart .card-bg-position {
              left: -82px;
              top: -38px;
              transform: scale(0.6);
            }
            &.carb-conscious .card-bg-position {
              left: -72px;
              top: -35px;
              transform: scale(0.85);
            }
            &.calorie-command .card-bg-position {
              left: -113px;
              top: -70px;
              transform: scale(0.85);
            }
            &.conquer-cravings .card-bg-position {
              left: -62px;
              top: -25px;
              transform: scale(0.85);
            }

            .card-subtitle {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
