.main-container .main-content .left-nav.content-box,
.left-nav.content-box {
  height: 100%;
  min-height: 734px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;

  .user-avatar {
    cursor: pointer;
  }

  .left-nav-back {
    display: none;
  }

  .user-info {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .user-avatar.MuiAvatar-root,
    .user-avatar-fallback {
      height: 94px;
      width: 94px;
    }
    .user-avatar.MuiAvatar-circle:not(.MuiAvatar-colorDefault) {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    }
    .avatar-plan-icon {
      height: 30px;
      width: 30px;
      background-size: cover;
      border-radius: 50%;
    }

    .user-name {
      margin-top: 13px;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 32px;
      color: #333333;
      text-align: center;
      text-transform: capitalize;
    }

    .user-username {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #959faf;
    }
  }

  .user-sub-badge {
    top: 20px;
    right: 20px;
    position: absolute;
    width: 56px;
    height: 24px;
  }

  .divider {
    width: 196px;
    margin-top: 24px;
    background-color: #f7f8fa;
  }

  .nav-list {
    margin-top: 20px;
    width: 240px;
    a {
      color: #323d47;
    }
    .nav-link {
      width: 240px;
      height: 48px;
      font-size: 14px;
      line-height: 20px;
      padding: 15px 17px 13px 22px;
    }
  }

  .left-nav-mobile-links,
  .left-nav-mobile-divider {
    display: none;
  }

  .btn-sign-out {
    display: none;
  }
}
