@include mobile {
  html body {
    #root {
      height: auto;
      .single-layout {
        background-color: white;
        height: auto;
        position: relative;
        .topbar-container {
          position: static;
        }
        .main-wrapper-bg1 {
          right: -510px;
          position: absolute;
        }
        .main-wrapper-bg2 {
          bottom: -360px;
          position: absolute;
        }
        .main-wrapper {
          z-index: 1201;
          .switch-wrapper > div {
            position: static;
          }
        }
        .simple-card-page-paper-wrapper {
          padding: pv(8px) pv(24px) pv(30px) pv(24px);
          .simple-card-page-paper {
            margin-bottom: pv(30px);
            margin-top: 0;
            .simple-card-page-title {
              max-width: 100%;
              padding: 0px pv(24px);
              font-size: pv(24px);
            }
            .simple-card-page-description {
              max-width: 100%;
              padding: 0px 24px;
              font-size: pv(13px);
            }
          }

          &.page-setup {
            .habits-list {
              width: pv(320px);
              padding-left: pv(13px);
              justify-content: center;
              .habits-item {
                min-width: pv(90px);
                width: auto;
                height: pv(36px);
                font-size: pv(12px);
                line-height: 1.3333;
                padding: pv(10px) pv(10px);
                margin: 0px pv(8px) pv(16px) 0px;
                &:nth-child(5n),
                &:nth-child(10n) {
                  margin-right: pv(30px);
                }
                &:nth-child(5n-1),
                &:nth-child(10n-1) {
                  margin-left: pv(30px);
                }
              }
            }
          }

          &.choose-plan .select-plan {
            max-width: 100%;
            height: auto;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            .plan-free {
              border: 2px solid #61a6f5;
              width: 250px;
              margin-top: 20px;
              height: 366px;
            }
          }

          &.page-choose-plan {
            &.plan-overview {
              .plan-card-component {
                width: pv(295px);
                padding: pv(12px);
                margin-bottom: pv(22px);
                .card-top {
                  width: 100%;
                  .card-bg-position {
                    transform: translate(pv(-30px), pv(30px));
                  }
                }
                .card-description {
                  padding: 0 pv(23px);
                }
                .card-features {
                  width: 100%;
                  padding: pv(16px) pv(8px) pv(0px) pv(8px);
                  margin: 0;
                  .card-feature-item {
                    max-width: 100%;
                    font-size: 14px;
                    .card-feature-item-icon {
                      margin-right: pv(24px);
                    }
                  }
                }
              }
            }

            &.addtional-plans {
              .addtional-plans-wrapper {
                margin-top: 0;
                max-width: 100%;
                padding: pv(12px);
                .change-plan {
                  width: calc(50% - #{pv(24px)});
                  margin: pv(12px);
                  .plan-card-component {
                    width: 100%;
                    .card-subtitle {
                      transform: scale(0.8);
                      width: 130%;
                      max-height: pv(32px);
                    }
                  }
                }
              }
            }
          }
        }
      }

      .main-layout {
        min-width: 0;
        .header-topbar {
          display: flex;
          justify-content: center;
          .header-topbar-nav-button {
            display: block;
            position: absolute;
            left: 400px;
          }
          .topbar-right {
            display: none;
          }
        }
        .main-container {
          .main-container-page-bg {
            background-size: 270%;
            background-position: top center;
          }
          .main-content {
            padding: pv(38px) pv(16px) pv(16px) pv(16px);
            .left-page-container {
              display: none;
            }
            .right-page-container {
              max-width: 100%;
            }
            .MuiGrid-spacing-xs-6 {
              margin: -10px -15px;
              & > .MuiGrid-item {
                padding: 10px 15px;
              }
            }
            .content-box {
              padding: pv(30px) pv(24px);
              .box-title {
                font-size: pv(22px);
                line-height: 1.4545;
              }
              .user-avatar,
              .user-avatar-fallback {
                height: pv(56px);
                width: pv(56px);
              }
              .avatar-area .upload-file-form {
                margin-top: pv(15px);
              }

              &.plan-box {
                height: auto;
                flex-flow: row wrap;
                .plan-bg {
                  transform: translate(pv(70px), pv(260px));
                }
                .plan-box-left {
                  width: 100%;
                  border-right: none;
                  .plan-info-row {
                    white-space: nowrap;
                  }
                  .plan-icon {
                    flex: none;
                  }
                }
                .plan-box-right {
                  .plan-daily-allowance {
                    margin-top: 55px;
                  }
                }
              }

              &.get-mobile-app-box {
                height: auto;
                .submit-phone-number-form {
                  flex-wrap: wrap;
                  .btn-submit-phone-number {
                    width: 100%;
                    margin: 0;
                    margin-top: 10px;
                  }
                }
              }

              &.edit-profile-box {
                height: auto;
                .profile-form {
                  margin-top: pv(39px);
                }
                .actions-row {
                  flex-wrap: wrap;
                  justify-content: center;
                  margin-top: pv(9px);
                  .btn-delete-account {
                    margin-top: pv(15px);
                    order: 2;
                  }
                  .btn-submit-profile-form {
                    width: 100%;
                    margin-top: pv(16px);
                    height: pv(40px);
                    padding: pv(9px) pv(16px);
                    .MuiButton-label {
                      font-size: pv(12px);
                    }
                  }
                }
              }

              &.current-plan-box {
                height: auto;
                .plan-card-component.inline {
                  max-width: 100%;
                  margin-top: pv(40px);
                  flex-wrap: wrap;
                  height: auto;
                  .card-left {
                    flex: none;
                    width: 100%;
                    height: auto;
                    .card-inline-content {
                      padding: pv(24px);
                      .card-title-inline-row {
                        margin: 0;
                      }
                      .card-title {
                        font-size: pv(24px);
                        text-align: left;
                        white-space: nowrap;
                      }
                      .card-description {
                        width: pv(200px);
                      }
                    }
                  }
                  .card-features {
                    width: 100%;
                    margin: pv(34px) 0 0 0;
                    .card-feature-item {
                      width: 100%;
                    }
                  }
                }
              }

              &.addtional-plans-box {
                .box-sub-title-description {
                  margin-left: 0;
                }
                .addtional-plans {
                  justify-content: space-between;
                  flex-wrap: wrap;
                  .change-plan {
                    margin-bottom: pv(16px);
                    .plan-card-component.minimal {
                      margin: 0;
                      width: pv(142px);
                    }
                  }
                }
              }

              &.become-a-pro {
                .pro-basic-compare-table {
                  width: calc(100% + #{pv(48px)});
                  margin-left: pv(-24px);
                  margin-top: pv(37px);
                }
              }

              &.learn-more-about-pro {
                padding: 0;
                height: auto;
                .learn-more-about-pro-header {
                  padding: pv(36px) pv(23px);
                  .learn-more-about-title {
                    font-size: pv(26px);
                    line-height: 1.5;
                  }
                }
                .learn-more-about-feature-list {
                  height: auto;
                  flex-wrap: nowrap;
                  padding: pv(16px) pv(0px);
                  .features-group {
                    max-width: 100%;
                  }
                }
              }

              &.payment-method {
                .mastercard-info {
                  font-size: pv(23px);
                }
              }
            }
          }
        }

        footer.footer {
          height: pv(85px);
          flex-flow: column nowrap;
          padding: pv(20px) pv(26px) pv(15px) pv(26px);
          .copy-right {
            margin-top: 20px;
            order: 2;
          }
          .footer-links {
            white-space: nowrap;
            order: 1;
          }
          .MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary {
            color: #959faf;
          }
        }
      }

      .main-wrapper {
        
      }
    }

    .MuiDialog-container .MuiPaper-root.MuiDialog-paper {
      margin: pv(16px);
      width: auto;
      .btn-close-modal {
        top: pv(4px);
        right: pv(4px);
      }
      .btn-change-plan {
        margin-bottom: 0;
      }
      .modal-content {
        width: 100%;
        padding: pv(40px) pv(24px);
        margin-bottom: 50px;
        .modal-title {
          max-width: 100%;
          margin-top: pv(16px);
          line-height: 1.5;
          font-size: pv(18px);
        }
        .modal-info-block {
          margin-top: pv(16px);
        }
        .ext-info-row {
          margin-top: 0;
          .modal-info-block {
            width: calc(50% - #{pv(7px)});
          }
        }
      }
      .modal-card {
        max-width: 100%;
        .plan-card-component {
          width: pv(295px);
          padding: pv(12px);
          margin-bottom: pv(22px);
          .card-top {
            width: 100%;
            .card-bg-position {
              transform: translate(pv(-30px), pv(30px));
            }
          }
          .card-description {
            margin-bottom: 20px;
            padding: 0 pv(23px);
          }
          .card-features {
            width: 100%;
            padding: pv(16px) pv(8px) pv(0px) pv(8px);
            margin: 0;
            .card-feature-item {
              max-width: 100%;
              font-size: 14px;
              .card-feature-item-icon {
                margin-right: pv(24px);
              }
            }
          }
        }
      }
      .common-confirm-dialog {
        .dialog-title {
          font-size: pv(22px);
          line-height: 1.33;
        }

        &.confirm-delete-account .dialog-actions {
          display: flex;
          flex-flow: column nowrap;
          height: auto;
          margin-top: pv(35px);
          .dialog-btn-confirm {
            order: 1;
            margin-top: pv(0px);
            margin-left: 0;
          }
          .dialog-btn-cancel {
            margin-left: 0;
            margin-top: pv(9px);
            order: 2;
          }
        }
      }
      .modal-info-block.your-plan .your-plan-box {
        height: auto;
      }
      .confirm-cancel-modal-content {
        .modal-ctas {
          margin: pv(16px) 0 0 0;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          .MuiButton-contained {
            height: pv(40px);
            padding: pv(9px) pv(16px);
          }
          .MuiButton-label {
            white-space: nowrap;
          }
        }
      }
      .learn-more-about-pro {
        padding: 0;
        height: auto;
        .learn-more-about-pro-header {
          padding: pv(60px) pv(23px) pv(36px) pv(23px);
          .learn-more-about-title {
            font-size: pv(26px);
            line-height: 1.5;
          }
        }
        .learn-more-about-feature-list {
          height: auto;
          flex-wrap: nowrap;
          padding: pv(16px) pv(0px);
          .features-group {
            max-width: 100%;
          }
        }
      }
    }

    .app-drawer {
      .left-nav.content-box {
        width: 300px;
        min-height: 0;
        position: relative;
        > * {
          flex: none;
        }
        .user-sub-badge {
          top: 24px;
          right: 24px;
        }
        .left-nav-back {
          display: block;
          height: 48px;
          width: 48px;
          position: absolute;
          top: 12px;
          left: 12px;
          color: #959faf;
          &:hover {
            background-color: transparent;
          }
          path {
            stroke: #333333;
          }
        }
        .user-info {
          margin-top: 95px;
        }
        .btn-sign-out {
          position: static;
          align-self: flex-start;
          margin-left: 49px;
          margin-bottom: 40px;
          margin-top: 32px;
        }
        .left-nav-mobile-divider {
          display: block;
        }
        .left-nav-mobile-links {
          display: block;
          margin-top: 16px;
          margin-left: 51px;
          align-self: flex-start;
          .left-nav-mobile-link {
            margin-top: pv(16px);
            display: block;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 1.71;
            color: #323555;
            opacity: 0.7;
          }
        }
      }
    }

    .btn-red {
      height: 40px;
    }

    .MuiButton-containedPrimary {
      height: 40px;
      padding: 9px;
    }

    .main-container
      .main-content
      .page-manage-subscription
      .pro-basic-compare-table,
    .pro-basic-compare-table {
      width: calc(100% + #{pv(48px)});
      .features-title {
        padding-left: pv(22px);
      }
      .feature-title-name {
        font-size: pv(12px);
      }
      .table-btn-upgrade {
        width: pv(82px);
        margin-right: pv(10px);
      }
      .table-btn-selected {
        width: pv(76px);
        margin-right: pv(10px);
      }
    }

    .MuiDialog-paper {
      margin: 4px;
    }

    .checkout-modal,
    .update-payment-modal,
    .sub-success-modal {
      .modal-content {
        padding: 5px 10px;
      }
    }

    .plan-card-component.inline .card-left .card-inline-bg-position {
      position: relative;
    }
  }
}
