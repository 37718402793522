.simple-card-page-paper {
  .simple-card-page-title {
    font-weight: 900;
    font-size: 32px;
    line-height: 1.25;
    text-align: center;
    color: #2b2b2b;
    margin-bottom: 14px;
    width: 356px;
  }
  .simple-card-page-description {
    font-size: 16px;
    line-height: 1.375;
    text-align: center;
    color: #959faf;
    margin-bottom: 20px;
    width: 356px;
  }
  strong {
    font-weight: 800;
  }
}
