.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 12px 0px 0px 0px;
  max-width: 500px;
  padding: 10px 19px !important;
  background: #f7f8fa;
  border-radius: 8px;
  height: 44px;
  border-radius: 4px;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}

.checkout-modal,
.update-payment-modal,
.sub-success-modal {
  .modal-content {
    width: 612px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    padding: 40px 56px;
    .modal-title {
      font-weight: 900;
      font-size: 26px;
      line-height: 1.23;
      text-align: center;
      color: #323d47;
      margin-top: 0px;
      width: 400px;
    }
    .modal-sub-title {
      margin-top: 11px;
      text-align: center;
    }
    .modal-content {
      margin-top: 14px;
    }
    .modal-info-block {
      &.payment-method-card-form {
        overflow: hidden;
        margin-top: 28px;
        margin-bottom: 8px;
      }
      &.error {
        .StripeElement {
          border: 1px solid #ff5858;
        }
      }
      .modal-info-block-title {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.29;
        color: #323555;
      }
      &.your-plan {
        width: 100%;
        .your-plan-box {
          position: relative;
          padding: 22px 31px;
          margin-top: 16px;
          height: auto;
          background: #f7f8fa;
          border-radius: 10px;
          .plan-title {
            font-size: 14px;
            line-height: 1.57;
            color: #2e2e2c;
          }
          .plan-price {
            font-style: normal;
            font-weight: 900;
            font-size: 30px;
            line-height: 36px;
            color: #323d47;
            margin-top: 12px;
            .plan-origin-price {
              margin-left: 11px;
              display: inline;
              font-weight: 500;
              font-size: 18px;
              line-height: 22px;
              text-decoration-line: line-through;
              color: #bfc6d4;
            }
          }
          .plan-expires {
            margin-top: 26px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 1.5;
            color: #323555;
          }
          .success-check {
            margin-left: 8px;
          }
          .plan-bage-pro {
            position: absolute;
            background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
            color: white;
            font-weight: 900;
            width: 44px;
            height: 24px;
            top: 22px;
            right: 24px;
          }
        }
      }
      &.name-on-card {
        margin-top: 32px;
      }
      &.card-number {
        margin-top: 32px;
        position: relative;
        .card-icon {
          position: absolute;
          top: 45px;
          right: 18px;
          height: 16px;
          width: 16px;
        }
      }
      &.expiration {
        width: 149px;
        display: inline-block;
      }
      &.cvc {
        width: 149px;
        display: inline-block;
      }
      &.zip-code {
        width: 149px;
        display: inline-block;
        input {
          opacity: 1;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.5;
          color: #323555;
          font-family: Arial, Helvetica, sans-serif;
        }
        ::placeholder {
          opacity: 1;
          color: #a9abb9;
        }
      }
      &.final-description {
        margin-top: 16px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 1.5;
        color: #959faf;
      }
      .input-name-on-card
        .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        margin-top: 12px !important;
      }
      .supported-card-types {
        margin-top: 6px;
        img {
          height: 22px;
          width: 32px;
          margin-right: 10px;
        }
      }
    }
    .ext-info-row {
      margin-top: 32px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
        margin-top: 12px;
      }
    }
    .error-message {
      margin-top: 20px;
      .error {
        color: #ff5858;
      }
    }
    .modal-description {
      margin-top: 14px;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #333333;
    }
    .btn-checkout {
      margin-top: 45px;
      margin-bottom: 13px;
    }
    .btn-close-modal {
      color: #000000;
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .modal-bottom-link {
    cursor: pointer;
    margin-top: 7px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.57;
    color: #61a6f5;
  }

  .StripeElement--invalid,
  .Mui-error {
    border: 1px solid #ff5858 !important;
  }
}

.sub-success-modal .checkout-modal-content .modal-info-block.your-plan {
  .your-plan-box {
    margin-top: 31px;
    height: auto;
  }
}
