.main-container .main-content .page-manage-subscription {
  .content-box {
    .membership-title {
      margin-top: 16px;
    }
    .membership-pro-badge {
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      width: 87px;
      height: 44px;
      margin-top: 10px;
    }
    .membership-discription {
      margin-top: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
      color: #323d47;
      &.mt-20 {
        margin-top: 20px;
      }
    }
    .btn-upgrade {
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      margin-top: 23px;
      height: 40px;
      width: 175px;
    }
    .btn-promo {
      margin-top: 23px;
      height: 40px;

      background: rgb(245, 246,	249);
      border-radius: 5px;
      padding: 2px 10px 0 20px;
      display: flex;
      align-items: left;

      .MuiButton-label {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 1;
        text-align: left;
        letter-spacing: 0.2px;
        color: rgb(110, 207, 27);
      }

      .arrow {
        margin-top: -4px;
        margin-left: 50px;
        color: rgb(32, 33, 58);
      }
    }
    .promo-code {
      color: #000000;
      text-align: center;
      padding: 15px 0 0 20px;
      letter-spacing: 0.1px;
      font-weight: 400;
      width: 150px;
    }
    .promo-code-bottom {
      color: #ffffff;
      text-align: center;
      padding: 8px 0 0 0px;
      letter-spacing: 0.1px;
      font-weight: 400;
      font-size: 12px;
    }
    .btn-membership-cta {
      display: block;
      .MuiButton-label {
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 1.57;
        letter-spacing: 0.5px;
        text-transform: none;
        color: #61a6f5;
      }
      &.Mui-disabled .MuiButton-label {
        color: #323d47;
        opacity: 0.5;
      }
    }
    .update-payment-method.way-to-change-payment-method {
      color: #323d47;
      margin-left: 0;
      a {
        color: #61a6f5;
        text-decoration: none;
      }
    }
    .cancel-sub {
      margin-left: -8px;
      margin-top: 33px;
    }
    .update-payment-method {
      margin-left: -8px;
      margin-top: 12px;
      &.MuiButtonBase-root.Mui-disabled .MuiButton-label {
        opacity: 1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.43;
        color: #323d47;
        letter-spacing: normal;
      }
      &.can-update-payment-method {
        margin-top: 54px;
      }
    }
  }

  .content-box.become-a-pro {
    .become-a-pro-title {
      text-align: center;
      font-style: normal;
      font-weight: 900;
      font-size: 26px;
      line-height: 1.23;
      color: #333333;
      .pro-badge {
        display: inline;
        margin-left: 10px;
        font-weight: 900;
        font-size: 20px;
        background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
        border-radius: 4px;
        padding: 9px 10px 5px 11px;
        text-transform: uppercase;
        color: white;
      }
    }
    .become-a-pro-sub-title {
      margin-top: 16px;
      font-style: normal;
      font-weight: 800;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #323d47;
    }
    .become-a-pro-price {
      font-style: normal;
      line-height: 31px;
      text-align: center;
      opacity: 0.8;
      .original-price {
        font-size: 26px;
        font-weight: 900;
        margin: 16px 10px;
        display: inline-block;
        color: #c4c4c4;
        text-decoration-line: line-through;
      }
      .current-price {
        font-size: 26px;
        font-weight: 900;
        margin: 16px 10px;
        display: inline-block;
        color: #fe5685;
      }
    }
  }

  .content-box.learn-more-about-pro {
    padding: 0;
    overflow: hidden;
    .learn-more-about-pro-header {
      padding: 40px;
      height: 171px;
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      .learn-more-about-title {
        text-align: center;
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 1.23;
        color: white;
        .pro-badge {
          display: inline;
          margin-left: 10px;
          font-weight: 900;
          font-size: 20px;
          background: white;
          border-radius: 4px;
          padding: 9px 10px 5px 11px;
          text-transform: uppercase;
          color: #e4668d;
        }
      }
      .learn-more-about-sub-title {
        margin: auto;
        margin-top: 17px;
        max-width: 331px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
        color: #ffffff;
      }
    }
    .learn-more-about-pro-bottom {
      height: 138px;
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      padding: 33px;
      text-align: center;
      .learn-more-about-bottom-text {
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        text-align: center;
      }
      .btn-upgrade-bottom {
        height: 40px;
        margin: auto;
        margin-top: 15px;
        background: white;

        span {
          color: #D66D8E;
        }
      }
    }
    .learn-more-about-feature-list {
      display: flex;
      flex-flow: column wrap;
      padding: 24px;
      height: 817px;
    }
    .features-group {
      max-width: 375px;
      margin-bottom: 10px;
      .feature-group-title {
        padding: 16px 24px;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 1;
        color: #2e2e2c;
      }
      .feature-item {
        display: flex;
        flex-flow: row nowrap;
        .feature-icon {
          padding: 16px;
          width: 80px;
          height: 80px;
        }
        .feature-right {
          padding: 19px 8px;
          .feature-name {
            font-size: 16px;
            line-height: 19px;
            color: #102843;
          }
          .feature-description {
            margin-top: 2px;
            max-width: 232px;
            font-size: 14px;
            line-height: 16px;
            color: #929292;
          }
        }
      }
    }
  }

  .pro-basic-compare-table {
    border-collapse: collapse;
    width: 100%;
    td {
      text-align: center;
    }
    .features-title {
      padding-left: 24%;
      display: flex;
      flex-flow: row;
      justify-content: flex-start;
      height: 100%;
      .feature-title-name {
        margin-left: 16px;
        text-align: left;
      }
      .what-you-get {
        font-weight: 900;
        font-size: 12px;
        line-height: 1.83;
        text-align: center;
        color: #323d47;
      }
    }
    .feature-check,
    .feature-cross {
      width: 16px;
      height: 16px;
    }
    tr {
      height: 48px;
    }
    tr:nth-child(2n) {
      background-color: #f7f8fa;
    }
    th.features {
      width: 64.1%;
      .features-title {
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 1.83;
        text-align: center;
        color: #323d47;
      }
    }
    th.basic {
      width: 56px;
      font-size: 18px;
      font-weight: 900;
    }
    th.pro {
      font-size: 16px;
      font-weight: 900;
      .pro-badge {
        width: 44px;
        height: 24px;
        margin-top: 5px;
      }
    }
    .table-btn-selected {
      margin: auto;
      margin-top: 14px;
      height: 40px;
      width: 96px;
      background: #f7f8fa;
      border-radius: 5px;
      font-weight: 900;
      font-size: 12px;
      line-height: 1.83;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #323d47;
      padding: 10px;
    }
    .table-btn-upgrade {
      margin: auto;
      margin-top: 14px;
      height: 40px;
      width: 96px;
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
    }
  }

  .confirm-cancel-modal-content {
    width: 612px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    position: relative;
    padding: 40px 0px;
    .btn-close-modal {
      color: #000000;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .modal-title {
      font-weight: 900;
      font-size: 26px;
      line-height: 1.23;
      text-align: center;
      color: #323d47;
      margin-top: 0px;
      width: 400px;
    }
    .modal-sub-title {
      margin-top: 14px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
      text-align: center;
      color: #323d47;
    }
    .pro-basic-compare-table {
      margin-top: 40px;
    }
    .modal-ctas {
      margin-top: 40px;
      margin-right: 46px;
      align-self: flex-end;
      .btn {
        height: 50px;
        padding: 14px 34px 11px 33px;
      }
      .btn-cancel-sub {
        background: #f7f8fa;
        border-radius: 5px;
        margin-right: 10px;

        .MuiButton-label {
          font-weight: 900;
          font-size: 12px;
          line-height: 1.83;
          text-align: center;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #323d47;
        }
      }
      .btn-stay-pro {
      }
    }
  }

  .content-box.payment-method {
    .mastercard-info {
      margin-top: 10px;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 1.25;
      color: #102843;
    }
    .card-brand {
      height: 22px;
      width: 32px;
      display: inline-block;
      margin-left: 10px;
    }
    .expire-date {
      margin-top: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.43;
      color: #323d47;
    }
    .payment-method-icon {
      margin-top: 16px;
      width: 49px;
    }
  }
}

.learn-more-about-pro-modal {
  .btn-close-modal {
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .content-box.learn-more-about-pro {
    width: 720px;
    max-width: 100%;
    padding: 0;
    overflow: hidden;
    .learn-more-about-pro-header {
      padding: 40px;
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      .learn-more-about-title {
        text-align: center;
        font-style: normal;
        font-weight: 900;
        font-size: 26px;
        line-height: 1.23;
        color: white;
        .pro-badge {
          display: inline;
          margin-left: 10px;
          font-weight: 900;
          font-size: 20px;
          background: white;
          border-radius: 4px;
          padding: 9px 10px 5px 11px;
          text-transform: uppercase;
          color: #e4668d;
        }
      }
      .learn-more-about-sub-title {
        margin: auto;
        margin-top: 17px;
        max-width: 331px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.43;
        text-align: center;
        color: #ffffff;
      }
    }
    .learn-more-about-pro-bottom {
      height: 188px;
      background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
      padding: 33px;
      text-align: center;
      .learn-more-about-bottom-text {
        font-style: normal;
        font-weight: 800;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        text-align: center;
      }
      .btn-upgrade-bottom {
        height: 40px;
        margin: auto;
        margin-top: 15px;
        background: white;

        span {
          color: #D66D8E;
        }
      }
    }
    .learn-more-about-feature-list {
      display: flex;
      flex-flow: column wrap;
      padding: 24px;
      height: 817px;
    }
    .features-group {
      max-width: 375px;
      margin-bottom: 10px;
      .feature-group-title {
        padding: 16px 24px;
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 1;
        color: #2e2e2c;
      }
      .feature-item {
        display: flex;
        flex-flow: row nowrap;
        .feature-icon {
          padding: 16px;
          width: 80px;
          height: 80px;
        }
        .feature-right {
          padding: 19px 8px;
          .feature-name {
            font-size: 16px;
            line-height: 19px;
            color: #102843;
          }
          .feature-description {
            margin-top: 2px;
            max-width: 232px;
            font-size: 14px;
            line-height: 16px;
            color: #929292;
          }
        }
      }
    }
  }
}

.pro-basic-compare-table {
  border-collapse: collapse;
  width: 100%;
  td {
    text-align: center;
  }
  .features-title {
    padding-left: 24%;
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    height: 100%;
    .feature-title-name {
      margin-left: 16px;
      text-align: left;
    }
    .what-you-get {
      font-weight: 900;
      font-size: 12px;
      line-height: 1.83;
      text-align: center;
      color: #323d47;
    }
  }
  .feature-check,
  .feature-cross {
    width: 16px;
    height: 16px;
  }
  tr {
    height: 48px;
  }
  tr:nth-child(2n) {
    background-color: #f7f8fa;
  }
  th.features {
    width: 64.1%;
    .features-title {
      font-style: normal;
      font-weight: 900;
      font-size: 12px;
      line-height: 1.83;
      text-align: center;
      color: #323d47;
    }
  }
  th.basic {
    width: 56px;
    font-size: 18px;
    font-weight: 900;
  }
  th.pro {
    font-size: 16px;
    font-weight: 900;
    .pro-badge {
      width: 44px;
      height: 24px;
      margin-top: 5px;
    }
  }
  .table-btn-selected {
    margin: auto;
    margin-top: 14px;
    height: 40px;
    width: 96px;
    background: #f7f8fa;
    border-radius: 5px;
    font-weight: 900;
    font-size: 12px;
    line-height: 1.83;
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #323d47;
    padding: 10px;
  }
  .table-btn-upgrade {
    margin: auto;
    margin-top: 14px;
    height: 40px;
    width: 96px;
  }
}

.confirm-cancel-modal-content {
  width: 612px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  padding: 40px 0px;
  .btn-close-modal {
    color: #000000;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .modal-title {
    font-weight: 900;
    font-size: 26px;
    line-height: 1.23;
    text-align: center;
    color: #323d47;
    margin-top: 0px;
    width: 400px;
  }
  .modal-sub-title {
    margin-top: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #323d47;
  }
  .pro-basic-compare-table {
    margin-top: 40px;
  }
  .modal-ctas {
    margin-top: 40px;
    margin-right: 46px;
    align-self: flex-end;
    .btn {
      height: 50px;
      padding: 14px 34px 11px 33px;
    }
    .btn-cancel-sub {
      background: #f7f8fa;
      border-radius: 5px;
      margin-right: 10px;

      .MuiButton-label {
        font-weight: 900;
        font-size: 12px;
        line-height: 1.83;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #323d47;
      }
    }
    .btn-stay-pro {
    }
  }
}
