@use 'sass:math';

@mixin desktop {
  @media only screen and (min-width: 640px) {
      @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: 640px) {
      @content;
  }
}

$mobile-vw-viewport: 375;

@function pv($px) {
  $vw-context: $mobile-vw-viewport * 0.01 * 1px;
  @return math.div($px, $vw-context);
  //@return $px / $vw-context * 1vw;
  @return $px;
}
