.page-account-view {
  height: calc(100% + 30px);
  align-content: flex-start;

  .MuiGrid-item {
    height: auto;
  }

  .content-box.profile-box {
    height: 264px;

    .profile-info-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 14px;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #323d47;
    }

    .profile-icon {
      display: block;
      height: 24px;
      width: 24px;
      margin-right: 14px;
      &.icon-mail {
        background-image: url("../../public/images/profile/mail.svg");
      }
      &.icon-height {
        background-image: url("../../public/images/profile/height.svg");
      }
      &.icon-cake {
        background-image: url("../../public/images/profile/cake.svg");
      }
    }

    .content-box-link {
      margin-top: 33px;
    }
  }

  .content-box.membership-box {
    height: 264px;

    .membership-title {
      display: block;
      margin-top: 10px;
    }
    &.pro {
      .membership-title {
        font-size: 26px;
        font-weight: 900;
        color: white;
        background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
        border-radius: 4px;
        width: 77px;
        height: 42px;
        line-height: 46px;
        text-align: center;
        text-transform: uppercase;
      }
      .pro-description {
        margin-top: 28px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #323d47;
      }
    }
    &.free {
      background: #fff linear-gradient(to right,
      #c5738f 20px, #9267a9 100%
      ) 0px 120px no-repeat;

      .change-to-pro {
        margin-top: 28px;
        .change-to-pro-description {
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
    .btn-go-pro.MuiButtonBase-root.MuiButton-root.MuiButton-text {
      margin-top: 22px;
      padding: 10px 36px 8px 36px;

      height: 40px;
      background: #ffffff;
      border-radius: 5px;
      font-style: normal;
      font-weight: 750;
      font-size: 12px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: #D66D8E;

      .MuiButton-label {
        font-weight: 750;
      }
    }
  }

  .content-box.plan-box {
    height: 214px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    .plan-box-left {
      width: 50%;
      border-right: 1px solid #f7f8fa;
      padding-right: 20px;
    }
    .plan-box-right {
      padding-left: 20px;
    }

    .plan-info-row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 10px;
      .plan-icon {
        margin-left: 10px;
        margin-top: -6px;
        height: 28px;
        width: 28px;
        border-radius: 50%;
        background-size: cover;
      }
    }

    .plan-description {
      margin-top: 8px;

      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #323d47;
    }

    .btn-change-plan {
      margin-top: 20px;
    }

    .plan-daily-allowance {
      width: 220px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      .plan-daily-allowance-chart {
        margin-top: 15px;
        height: 80px;
        width: 130px;
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: flex-end;
        .plan-daily-allowance-progress {
          position: absolute;
          top: 0px;
          left: 0px;
          transform: rotate(180deg) !important;
          stroke-linecap: round;
        }
        .plan-daily-allowance-number {
          margin-top: 10px;
          font-weight: 900;
          font-size: 40px;
          line-height: 40px;
          text-align: center;
          color: #357392;
        }
      }
      .plan-daily-allowance-description {
        font-weight: 600;
        margin-top: 10px;
        white-space: nowrap;
        max-width: 100%;
      }
    }

    .plan-box-bg-position {
      position: absolute;
    }
  }

  .content-box.get-mobile-app-box {
    height: 222px;

    .submit-your-phone-number-description {
      margin-top: 25px;
    }
    .submit-phone-number-form {
      margin-top: 16px;
      display: flex;
      align-items: flex-start;
    }
    .phone-number-input {
      width: 395px;
      margin: 0px;
      .MuiOutlinedInput-root {
        margin-top: 0px;
      }
    }
    #phone-helper-text {
      line-height: 24px;
      display: flex;
      align-items: center;
    }
    .btn-submit-phone-number {
      margin-left: 14px;
      padding: 10px 24px 8px 24px;
      height: 40px;
      background: #357392;
      border-radius: 5px;
      min-width: 161px;
      .MuiButton-label {
        font-weight: 750;
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #ffffff;
      }
    }
  }
}
