.plan-card-component {
  width: 343px;
  height: 427px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 12px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .card-top {
    width: 319px;
    height: 158px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  }
  .card-bg-position {
    position: absolute;
  }
  .card-right,
  .card-inline-content {
    padding: 16px 16px 24px 0px;
    width: 210px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: space-between;
    .plan-icon {
      height: 38px;
      width: 38px;
    }

    .card-title {
      font-weight: 900;
      font-size: 24px;
      line-height: 28px;
      display: flex;
      align-items: flex-end;
      text-align: right;
      color: #ffffff;
    }
  }
  .card-pro-badge {
    width: 44px;
    height: 24px;
    background: linear-gradient(90deg, #c5738f 5%, #9267a9 90%);
    border-radius: 4px;
    font-size: 14px;
    font-weight: 900;
    color: white;
    text-align: center;
    line-height: 24px;
  }
  .card-description {
    width: 273px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #323555;
    opacity: 0.7;
  }

  .card-features {
    margin-top: 17px;
    align-self: flex-start;
    margin-left: 15px;

    .card-feature-item {
      width: 310px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      .card-feature-item-icon {
        flex: none;
        width: 33px;
        height: 33px;
        margin-bottom: 14px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
        border-radius: 50%;
        background-color: #ffffff;
        background-image: url("/public/images/plans/plan-features-check.svg");
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 25px;
      }
      .card-feature-item-text {
        flex: auto;
        height: 47px;
        padding-top: 8px;
        padding-bottom: 18px;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
      }

      &:not(:last-child) {
        .card-feature-item-text {
          border-bottom: 1px solid rgba(50, 53, 85, 0.05);
        }
        margin-bottom: 12px;
      }
    }
  }
}

.plan-card-component.inline {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 750px;
  height: 157px;
  padding: 0;
  box-shadow: none;

  .card-left {
    width: 316px;
    height: 157px;
    margin-right: 50px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;

    .card-inline-bg-position {
      position: absolute;
    }
  }

  .card-inline-content {
    width: 100%;
    padding: 17px 32px;
    align-items: flex-start;
    justify-content: flex-start;
    .card-pro-badge {
      width: 33px;
      height: 18px;
      font-size: 12px;
      line-height: 20px;
    }
    .card-title-inline-row {
      margin-top: 13px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .card-title {
        font-size: 30px;
        line-height: 32px;
      }
      .plan-icon {
        margin-left: 16px;
        width: 34px;
        height: 34px;
        background-size: cover;
      }
    }
    .card-description {
      margin-top: 17px;
      width: 260px;
      font-size: 14px;
      line-height: 1.43;
      color: white;
      opacity: 1;
    }
  }

  .card-features {
    align-self: center;
    margin: 0;
    margin-left: 30px;
    width: 310px;
    height: 131px;
    .card-feature-item {
      height: 44px;
      .card-feature-item-text {
        height: 44px;
        padding-top: 9px;
        padding-bottom: 8px;
      }
      &:not(:last-child) {
        .card-feature-item-text {
          border-bottom: 1px solid rgba(50, 53, 85, 0.05);
        }
        margin-bottom: 5px;
      }
    }
  }

  &.pro .card-inline-content .card-description {
    margin-top: 13px;
  }
}

.plan-card-component.minimal {
  height: 202px;
  width: 142px;
  padding: 0px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  text-transform: none;
  letter-spacing: 0px;

  .card-top {
    height: 87px;
    width: 100%;
  }
  .card-icon {
    position: absolute;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    background-size: cover;
    top: 72px;
  }
  .card-content {
    padding: 0px 5px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .card-pro-badge {
      margin-top: 6px;
      width: 33px;
      height: 18px;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .card-title {
    margin-top: 26px;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #4a4a4a;
  }
  .card-subtitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    color: #878795;

    transform: scale(0.833);
    width: 120%;
  }
}

.better-balance {
  .plan-bgc {
    background: url("/public/images/plans/cards/better-balance-bg.png") no-repeat;
    background-size: contain;
  }
  .plan-bgc-small {
    background: url("/public/images/plans/cards/better-balance-bg-only.png") no-repeat center;
    background-size: contain;
  }
  .card-bg-position {
    left: -86px;
    top: -10px;
  }
  .card-inline-bg-position {
    right: -116px;
    top: -20px;
    transform: scale(0.7);
  }
  .plan-box-bg-position {
    right: -100px;
    top: 0;
    background: url("/public/images/plans/better-balance-bg-side.png") no-repeat !important;
  }
  .plan-bg {
    width: 224px;
    height: 214px;
    background-size: contain !important;
  }
  .plan-icon {
    background: url("/public/images/plans/better-balance.svg") no-repeat;
  }
  .plan-icon-bg {
    background-color: #b7acff;
  }
}
.keeping-keto {
  .plan-bgc {
    background: url("/public/images/plans/cards/keeping-keto-bg.png") no-repeat;
    background-size: contain;
  }
  .plan-bgc-small {
    background: url("/public/images/plans/cards/keeping-keto-bg-only.png") no-repeat center;
    background-size: contain;
  }
  .card-bg-position {
    left: -62px;
    top: 0px;
  }
  .card-inline-bg-position {
    right: -80px;
    top: 33px;
    transform: scale(0.8);
  }
  .plan-box-bg-position {
    right: -100px;
    top: 0;
    background: url("/public/images/plans/keeping-keto-bg-side.png") no-repeat !important;
  }
  .plan-bg {
    width: 224px;
    height: 214px;
    background-size: contain !important;
  }
  .plan-icon {
    background: url("/public/images/plans/keeping-keto.svg") no-repeat;
  }
  .plan-icon-bg {
    background-color: #42e7d1;
  }
}
.conquer-cravings {
  .plan-bgc {
    background: url("/public/images/plans/cards/conquer-cravings-bg.png") no-repeat;
    background-size: contain;
  }
  .plan-bgc-small {
    background: url("/public/images/plans/cards/conquer-cravings-bg-only.png") no-repeat center;
    background-size: contain;
  }
  .card-bg-position {
    left: -62px;
    top: 0px;
  }
  .card-inline-bg-position {
    right: -105px;
    top: 24px;
    transform: scale(0.7);
  }
  .plan-box-bg-position {
    right: -100px;
    top: 0;
    background: url("/public/images/plans/conquer-cravings-bg-side.png") no-repeat !important;
  }
  .plan-bg {
    width: 224px;
    height: 214px;
    background-size: contain !important;
  }
  .plan-icon {
    background: url("/public/images/plans/conquer-cravings.svg") no-repeat;
  }
  .plan-icon-bg {
    background-color: #15dc41;
  }
}
.carb-conscious {
  .plan-bgc {
    background: url("/public/images/plans/cards/carb-conscious-bg.png") no-repeat;
    background-size: contain;
  }
  .plan-bgc-small {
    background: url("/public/images/plans/cards/carb-conscious-bg-only.png") no-repeat center;
    background-size: contain;
  }
  .card-bg-position {
    left: -62px;
    top: 0px;
  }
  .card-inline-bg-position {
    right: -93px;
    top: 20px;
    transform: scale(0.7);
  }
  .plan-box-bg-position {
    right: -100px;
    top: 0;
    background: url("/public/images/plans/carb-conscious-bg-side.png") no-repeat !important;
  }
  .plan-bg {
    width: 224px;
    height: 214px;
    background-size: contain !important;
  }
  .plan-icon {
    background: url("/public/images/plans/carb-conscious.svg") no-repeat;
  }
  .plan-icon-bg {
    background-color: #f7dc3e;
  }
}
.calorie-command {
  .plan-bgc {
    background: url("/public/images/plans/cards/calorie-command-bg.png") no-repeat;
    background-size: contain;
  }
  .plan-bgc-small {
    background: url("/public/images/plans/cards/calorie-command-bg-only.png") no-repeat center;
    background-size: contain;
  }
  .card-bg-position {
    left: -85px;
    top: -30px;
  }
  .card-inline-bg-position {
    right: -146px;
    top: -30px;
    transform: rotate(-65deg) scale(0.9);
  }
  .plan-box-bg-position {
    right: -100px;
    top: 0;
    background: url("/public/images/plans/calorie-command-bg-side.png") no-repeat !important;
  }
  .plan-bg {
    width: 224px;
    height: 214px;
    background-size: contain !important;
  }
  .plan-icon {
    background: url("/public/images/plans/calorie-command.svg") no-repeat;
  }
  .plan-icon-bg {
    background-color: #67aaf7;
  }
}
.sugar-smart {
  .plan-bgc {
    background: url("/public/images/plans/cards/sugar-smart-bg.png") no-repeat;
    background-size: contain;
  }
  .plan-bgc-small {
    background: url("/public/images/plans/cards/sugar-smart-bg-only.png") no-repeat center;
    background-size: contain;
  }
  .card-bg-position {
    left: -76px;
    top: 11px;
  }
  .card-inline-bg-position {
    right: -130px;
    top: 15px;
    transform: scale(0.9);
  }
  .plan-box-bg-position {
    right: -100px;
    top: 0;
    background: url("/public/images/plans/sugar-smart-bg-side.png") no-repeat !important;
  }
  .plan-bg {
    width: 224px;
    height: 214px;
    background-size: contain !important;
  }
  .plan-icon {
    background: url("/public/images/plans/sugar-smart.svg") no-repeat;
  }
  .plan-icon-bg {
    background-color: #fcd0fe;
  }
}
