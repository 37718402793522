.main-container .main-content .page-plan-summary {
  .content-box {
    padding: 30px;
  }
  .plan-card-component.inline {
    margin-top: 10px;
  }
  .addtional-plans {
    display: flex;
    flex-flow: row nowrap;
    margin-top: 18px;
  }
  .plan-card-component.minimal {
    margin-right: 10px;
    &.better-balance .card-bg-position {
      transform: scale(0.6);
      top: -55px;
      left: -93px;
    }
    &.keeping-keto .card-bg-position {
      left: -42px;
      top: -30px;
      transform: scale(0.75);
    }
    &.sugar-smart .card-bg-position {
      left: -82px;
      top: -38px;
      transform: scale(0.6);
    }
    &.carb-conscious .card-bg-position {
      left: -82px;
      top: -35px;
      transform: scale(0.75);
    }
    &.calorie-command .card-bg-position {
      left: -120px;
      top: -75px;
      transform: scale(0.75);
    }
    &.conquer-cravings .card-bg-position {
      left: -62px;
      top: -25px;
      transform: scale(0.75);
    }
  }
  .change-plan {
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: translate(0%, -1%);
    }
  }
}
.change-plan-modal {
  width: 571px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  .modal-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 1.38;
    text-align: center;
    color: #333333;
    margin-top: 64px;
    width: 400px;
  }
  .modal-card {
    margin-top: 14px;
  }
  .modal-description {
    margin-top: 14px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #333333;
  }
  .btn-change-plan {
    margin-top: 25px;
    margin-bottom: 30px;
    width: 263px;
  }
  .btn-close-modal {
    color: #000000;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
