@import-normalize;
@import "variables.scss";
@import "layouts/index.scss";

@font-face {
  font-family: "Avenir LT Std";
  src: url("/public/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/public/fonts/AvenirLTStd-Heavy.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/public/fonts/AvenirLTStd-Medium.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Medium.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/public/fonts/AvenirLTStd-Light.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/public/fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/public/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("/public/fonts/AvenirLTStd-Roman.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box !important;
  font-family: "Avenir LT Std";
  font-weight: 600;
}

strong,
b {
  font-weight: 700;
}

a {
  text-decoration: none;
}

.switch-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.switch-wrapper > div {
  position: absolute;
  will-change: opacity, transform;
  height: 100%;
  width: 100%;
}

.user-avatar-fallback {
  height: 100%;
  width: 100%;
}

.keyboard-datepicker .MuiOutlinedInput-adornedEnd {
  padding-right: 3px;
  .MuiIconButton-root.MuiButtonBase-root {
    padding: 12px;
    .MuiIconButton-label .MuiSvgIcon-root {
      height: 16px;
      width: 16px;
    }
  }
}

.MuiSvgIcon-root.MuiSelect-icon {
  height: 18px;
  width: 18px;
  top: calc(50% - 9px);
  right: 14px;
  color: #303030;
}

.MuiTypography-root.MuiPickersMonth-root.MuiPickersMonth-monthDisabled {
  color: #959faf;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f7f8fa inset !important;
}

.MuiInputBase-root.Mui-error {
  border: 1px solid #e53935;
}

.MuiPaper-root.MuiDialog-paper.MuiPaper-rounded {
  max-width: 100vw;
}

.btn-red.MuiButtonBase-root.MuiButton-root {
  background: #357392;
  border-radius: 5px;
  padding: 0px 31px;
  display: flex;
  align-items: center;
  .MuiButton-label {
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 1.83;
    text-align: center;
    letter-spacing: 0.5px;
    color: #ffffff;
  }
  &[disabled] {
    background: #bfc6d4;
  }
  &:hover {
    background: #357392;
  }
}

.btn-sm.MuiButtonBase-root.MuiButton-root {
  height: 28px;
  .MuiButton-label {
    font-weight: 900;
    font-size: 12px;
    line-height: 1.83;
    text-align: center;
    letter-spacing: 0.5px;
  }
  .MuiCircularProgress-root {
    height: 16px !important;
    width: 16px !important;
  }
}

.MuiCircularProgress-colorPrimary {
  color: none;
}
